import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import { CardBody } from './Card.Body';
import { CardCta } from './Card.Cta';
import { CardDescription } from './Card.Description';
import { CardFoot } from './Card.Foot';
import { CardInner } from './Card.Inner';
import { CardMeta } from './Card.Meta';
import { CardTitle } from './Card.Title';
import { CardBase, CardProvider } from './useCard';
import { CardStyleProps, useCardStyle } from './useCardStyle';
import { combineComponentChildren } from '../ui/Polymorphic';
import { CardImage } from './Card.Image';

export type CardProps<
  T extends CardBase = CardBase,
  D extends React.ElementType = 'article',
  P = NoProps
> = BoxProps<
  D,
  {
    item: T;
    /**
     * Remove the {@link CardLink} wrapper (linking to be added manually)
     */
    disableLinkWrap?: boolean;
    /**
     * Open the link in a new window
     */
    newWindow?: boolean;
  } & CardStyleProps &
    P
>;

export const CardRoot = <T extends CardBase = CardBase, D extends React.ElementType = 'article'>({
  component = 'article',
  item,
  disableLinkWrap,
  newWindow,
  children,
  ...rest
}: CardProps<T, D>) => {
  // Apply Card style
  const props = useCardStyle(rest);

  // The card children
  const inner = children || (
    <>
      <CardImage />
      <CardBody />
      <CardFoot />
    </>
  );

  return (
    <CardProvider value={item}>
      <Box elevation as={component} {...props}>
        <CardInner
          {...(!disableLinkWrap &&
            !!item.href && { href: item.href, ...(newWindow && { target: '_blank' }) })}>
          {inner}
        </CardInner>
      </Box>
    </CardProvider>
  );
};

const Card = combineComponentChildren(CardRoot, {
  Body: CardBody,
  Cta: CardCta,
  Description: CardDescription,
  Foot: CardFoot,
  Image: CardImage,
  Inner: CardInner,
  Meta: CardMeta,
  Title: CardTitle,
});

export default Card;
