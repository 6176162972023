'use client';

import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';
import Txt, { TxtProps } from '../ui/Txt';
import { PageEntryTypes } from '@/lib/parsers/entries';

export type PageTitleProps = TxtProps<'h1'> & Partial<PageHeaderProps>;

export default function PageTitle({ children, ...props }: PageTitleProps) {
  const page = useView() as PageEntryTypes;
  const title = page?.pageTitle ?? null;

  return (
    <Txt as="h1" variant="h1" cx={{ mB: 'none' }} {...props}>
      {children || title}
    </Txt>
  );
}
