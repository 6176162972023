'use client';

import React from 'react';
import Flex from '../ui/Flex';
import { TextField } from '@mui/material';
import { vars } from '@/theme/vars.css';
import Btn from '../ui/Btn';
import Box from '../ui/Box';
import { useRouter } from 'next/navigation';
import { useNavbarState } from '../NavBar/useNavbarState';

export default function SearchForm() {
  const router = useRouter();
  const setSearch = useNavbarState((s) => s.setSearch);
  const search = useNavbarState((s) => s.search);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const inputValue = (e.currentTarget[0] as HTMLInputElement).value;
    if (!inputValue) return;

    e.preventDefault();
    setSearch(false);
    // console.log('search submitted');
    router.push(`/search/?s=${inputValue}`);
  };

  return (
    <Box as="form" noValidate onSubmit={handleSubmit}>
      <Flex direction="row">
        <TextField
          sx={{ width: '100%' }}
          InputProps={{
            disableUnderline: true,
            sx: { fontSize: vars.typography.size.h5 },
          }}
          inputProps={{
            style: {
              fontSize: vars.typography.size.h5,
              color: vars.palette.colorSets.half.contrastText,
            },
            tabIndex: search ? 0 : -1,
          }}
          variant="standard"
          placeholder="Search"
        />
        <Btn
          tabIndex={-1}
          type="submit"
          startIcon="search"
          variant="unstyled"
          aria-label="search-submit"
          cx={{ fontSize: 'h4' }}
        />
      </Flex>
    </Box>
  );
}
