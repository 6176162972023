'use client';

import { PageEntryTypes } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import Image, { ImageProps } from '../Image';

export type PageFeatureImageProps = ImageProps;

export default function PageFeatureImage({ ...props }: PageFeatureImageProps) {
  const page = useView() as PageEntryTypes;
  const entryImage = page?.entryImage ?? null;

  return <Image {...entryImage} fixedRatio alt="feature-image" priority {...props} />;
}
