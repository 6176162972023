import Box from '@/components/ui/Box';
import ContextLayout from '../ContextLayout';
import ThemeLayout from '../ThemeLayout';
import { createLayout } from './createLayout';
import * as styles from './Layout.css';

export const layoutMainId = 'layout-main-content';

const LayoutDefault = createLayout(
  ['nav', 'header', 'body', 'afterBody', 'footer'],
  (props, { slots }) => {
    const { nav, header, body, afterBody, footer } = slots ?? {};

    return (
      <ThemeLayout {...{ colorTheme: props.colorTheme }}>
        <ContextLayout {...{ view: props.entry, ...props }}>
          <Box as="header">
            {nav}
            {header}
          </Box>
          <Box as="main" role="main" id={layoutMainId} tabIndex={-1} className={styles.main}>
            {body}
            {afterBody}
          </Box>
          {footer}
        </ContextLayout>
      </ThemeLayout>
    );
  }
);

export default LayoutDefault;
