'use client';

import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';
import Box, { BoxProps } from '../ui/Box';
import Txt from '../ui/Txt';

type PageDateProps = BoxProps<'div'> & Partial<PageHeaderProps>;

export default function PageDate({ children, ...props }: PageDateProps) {
  const page = useView() as Entry<'article', 'default'>;
  const articleDate = page?.postDate;

  return (
    <Box {...props}>
      <Txt as="span" variant="body">
        {children || articleDate}
      </Txt>
    </Box>
  );
}
