import { Sprinkles } from '@/theme/sprinkles.css';
import Box, { BoxProps } from '../ui/Box';
import Container, { ContainerProps } from '../ui/Container';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import PageTitle, { PageTitleProps } from './PageTitle';

export type PageHeaderRootProps = BoxProps<
  'div',
  {
    PageTitleProps?: PageTitleProps;
    variant?:
      | 'home'
      | 'overview'
      | 'communityIndex'
      | 'articleIndex'
      | 'article'
      | 'standard'
      | 'search'
      | 'contact'
      | 'careers';
    maxWidth?: ContainerProps['maxWidth'];
    show?: {
      date?: boolean;
      author?: boolean;
    };
    beforeTitle?: React.ReactNode;
    afterGrid?: React.ReactNode;
    afterCol?: React.ReactNode;
    beforeCol?: React.ReactNode;
    afterContainer?: React.ReactNode;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
  }
>;

const PageHeaderRoot = ({
  PageTitleProps,
  children,
  variant = 'standard',
  maxWidth,
  beforeTitle,
  afterGrid,
  afterCol,
  beforeCol,
  afterContainer,
  cx: customCx,
  GridProps: customGridProps,
  GridColProps,
  ...props
}: PageHeaderRootProps) => {
  const cx: Sprinkles = {
    position: 'relative',
    ...customCx,
  };

  const GridProps: GridProps = {
    cx: {
      rowGap: 'xl',
      pY: '5xl',
    },
    ...customGridProps,
  };

  const isHomePage = variant === 'home';

  return (
    <Box {...props} cx={cx}>
      <Container maxWidth={maxWidth}>
        {!isHomePage && (
          <Grid {...GridProps}>
            {beforeCol}
            <Grid.Col {...GridColProps}>
              {beforeTitle}
              <PageTitle {...PageTitleProps} />
              {children}
            </Grid.Col>
            {afterCol}
          </Grid>
        )}
        {afterGrid}
      </Container>
      {afterContainer}
    </Box>
  );
};

export default PageHeaderRoot;
