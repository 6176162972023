'use client';

import { AppGlobal } from '@/lib/parsers/globals';
import { fmtProperString } from '@/lib/utils/format';
import Icon from '../Icon';
import Btn, { BtnProps } from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import { TxtProps } from '../ui/Txt';
import { useGlobal } from '@/lib/getters/useGlobal';

export type SocialProps = TxtProps<
  'span',
  { links?: AppGlobal<'social_GlobalSet'>; FlexProps?: FlexProps; BtnProps?: BtnProps }
>;

const Socials = ({ links, FlexProps, BtnProps }: SocialProps) => {
  const socialGlobal = useGlobal({ handle: 'social' }, 'social_GlobalSet');
  const socialLinks = Object.entries(socialGlobal?.socialLinks ?? links?.socialLinks ?? {});

  return (
    <Flex {...FlexProps}>
      {socialLinks.map(([key, value]) => {
        if (!value) return null;
        let icon: JSX.Element | null = null;

        switch (key) {
          case 'instagram':
            icon = <Icon name="instagram" />;
            break;
          case 'linkedin':
            icon = <Icon name="linkedin" />;
            break;
          default:
            break;
        }

        if (!icon) return null;

        return (
          <Btn variant="nav" key={key} as="a" href={value} startIcon={icon} {...BtnProps}>
            {fmtProperString(key)}
          </Btn>
        );
      })}
    </Flex>
  );
};

export default Socials;
