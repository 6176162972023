'use client';

import Logo from '../Logo';
import NextAnchor from '../ui/NextAnchor';
import { useNavbarState } from './useNavbarState';

export default function NavLogo() {
  const setSearch = useNavbarState((s) => s.setSearch);
  return (
    <NextAnchor
      href="/"
      aria-label="Melbourne Arts Precinct Transformation logo - home"
      style={{ color: 'inherit' }}
      onClick={() => setSearch(false)}>
      <Logo />
    </NextAnchor>
  );
}
