'use client';

import { useView } from '@/lib/store/hooks';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import { PageHeaderProps } from '.';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';

type PageSocialsProps = FlexProps<'div'> & Partial<PageHeaderProps>;

export default function PageSocials({ children, ...props }: PageSocialsProps) {
  const t = useTranslations('actions');

  const page = useView();
  const pageTitle = maybeGet(page, 'pageTitle');

  const url = page?.url ?? '';
  const title = (pageTitle || page?.title) ?? '';

  // Linkedin
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

  // Twitter
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;

  // Facebook
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`;

  return (
    <Flex direction="row" columnGap="xs" alignItems="center" {...props}>
      <Txt as="span" allCaps>
        {children || t('share')}
      </Txt>
      <Flex direction="row" columnGap="xs">
        <Btn
          variant="unstyled"
          StartIconProps={{ name: 'linkedin', cx: { fontSize: 'h6' } }}
          target="_blank"
          href={linkedinUrl}
        />
        <Btn
          variant="unstyled"
          StartIconProps={{ name: 'twitter', cx: { fontSize: 'h6' } }}
          target="_blank"
          href={twitterUrl}
        />
        <Btn
          variant="unstyled"
          StartIconProps={{ name: 'facebook', cx: { fontSize: 'h6' } }}
          target="_blank"
          href={facebookUrl}
        />
      </Flex>
    </Flex>
  );
}
