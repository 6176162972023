'use client';
import { PropsWithChildren, useRef } from 'react';
import {
  createUnstyledAccordionStore,
  UnstyledAccordionContext,
  UnstyledAccordionOptions,
  UnstyledAccordionStore,
} from './useUnstyledAccordion';

export interface UnstyledAccordionProviderProps
  extends UnstyledAccordionOptions,
    PropsWithChildren {
  openFirst?: boolean;
}

const UnstyledAccordionProvider = ({ children, ...props }: UnstyledAccordionProviderProps) => {
  const storeRef = useRef<UnstyledAccordionStore>();
  if (!storeRef.current) {
    storeRef.current = createUnstyledAccordionStore(props);
  }
  return (
    <UnstyledAccordionContext.Provider value={storeRef.current}>
      {children}
    </UnstyledAccordionContext.Provider>
  );
};

export default UnstyledAccordionProvider;
