'use client';

import { useView } from '@/lib/store/hooks';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '../ui/Box';
import { PageEntryTypes } from '@/lib/parsers/entries';
import Video from '../Video';
import Btn from '../ui/Btn';
import * as styles from './PageHeader.css';
import { useState } from 'react';

type PageVideoProps = BoxProps;

export default function PageVideo({ ...props }: PageVideoProps) {
  const [playing, setPlaying] = useState<boolean>(false);

  const page = useView() as PageEntryTypes;
  const videoUrl = maybeGet(page, 'videoUrl') ?? null;

  return (
    <Box onClick={() => setPlaying(true)} className={styles.videoWrapper({ playing })} {...props}>
      {!playing && (
        <Btn
          className={styles.videoButton}
          variant="icon"
          size="small"
          color="accent"
          StartIconProps={{ name: 'play', cx: { fontSize: 'h2' } }}
        />
      )}
      <Video playing={playing} controls={true} videoUrl={videoUrl} />
    </Box>
  );
}
