'use client';

import Box, { BoxProps } from '../ui/Box';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';
import * as styles from './UnstyledAccordion.css';

export type UnstyledAccordionItemHandleProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    index?: number;
  }
>;

const UnstyledAccordionItemHandle = ({
  index,
  children,
  ...props
}: UnstyledAccordionItemHandleProps) => {
  const { toggle } = useUnstyledAccordionItem(index);

  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    props?.onClick?.(ev);
    toggle();
  };

  return (
    <Box className={styles.control()} role="button" {...props} onClick={handleClick}>
      {children}
    </Box>
  );
};

export default UnstyledAccordionItemHandle;
