import Icon, { IconName } from '../Icon';
import Box, { BoxProps } from '../ui/Box';

export type UnstyledAccordionItemCaretProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    icon?: IconName;
    openIcon?: IconName;
    isActive?: boolean;
    isAccordionItem?: boolean;
  }
>;

const UnstyledAccordionItemCaret = ({
  openIcon = 'minus',
  icon = 'plus',
  isAccordionItem = true,
  isActive,
  ...props
}: UnstyledAccordionItemCaretProps) => {
  return (
    isAccordionItem && (
      <Box {...props}>
        <Icon name={isActive ? openIcon : icon} />
      </Box>
    )
  );
};

export default UnstyledAccordionItemCaret;
