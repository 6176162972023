'use client';

import { themeDark } from '@/theme/mui/muiTheme';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

export const DarkTheme = (props: PropsWithChildren) => {
  return <MuiThemeProvider theme={themeDark} {...props} />;
};
