import Grid from '@/components/ui/Grid';
import ContextLayout from '../ContextLayout';
import { createLayout } from './createLayout';
import * as styles from './Layout.css';
import Container from '@/components/ui/Container';
import ThemeLayout from '../ThemeLayout';
import { layoutMainId } from './LayoutDefault';
import Box from '@/components/ui/Box';

const LayoutGrid = createLayout(
  ['nav', 'header', 'sidebar', 'body', 'afterBody', 'footer'],
  (props, { slots }) => {
    const { nav, header, sidebar, body, afterBody, footer } = slots ?? {};

    return (
      <ThemeLayout {...{ colorTheme: props.colorTheme }}>
        <ContextLayout {...{ view: props.entry, ...props }}>
          <Box as="header">
            {nav}
            {header}
          </Box>
          <Container className={styles.container}>
            <Grid cx={{ mB: '5xl', rowGap: '5xl' }}>
              <Grid.Col md={5}>{sidebar}</Grid.Col>
              <Grid.Col offsetMd={1} md={6}>
                <Box as="main" role="main" id={layoutMainId} tabIndex={-1} className={styles.main}>
                  {body}
                </Box>
              </Grid.Col>
            </Grid>
          </Container>
          {afterBody}
          {footer}
        </ContextLayout>
      </ThemeLayout>
    );
  }
);

export default LayoutGrid;
