import React from 'react';
import Txt, { TxtProps } from '../ui/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';
import { isArray } from 'lodash';
import Flex from '../ui/Flex';
import Divider from '../ui/Divider';

export type CardMetaProps<D extends React.ElementType = 'h2'> = TxtProps<D>;

export const CardMeta = <D extends React.ElementType>({ children, ...props }: CardMetaProps<D>) => {
  const { meta } = useCard();
  const content = children ?? meta;

  const hasContent = !!content && (!isArray(content) || content.length);

  return hasContent ? (
    content ? (
      <Flex direction="row" columnGap="3xs">
        {content.map((item: string, idx: number) => {
          return (
            <React.Fragment key={idx}>
              <Txt as="span" {...mergePropsClassName(props, style.meta)}>
                {item}
              </Txt>
              {idx !== content.length - 1 && (
                <Divider direction="vertical" color="accent" style={{ height: '1em' }} />
              )}
            </React.Fragment>
          );
        })}
      </Flex>
    ) : (
      <Txt as="span" {...mergePropsClassName(props, style.meta)}>
        {content}
      </Txt>
    )
  ) : null;
};
