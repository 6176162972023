'use client';

import SearchForm from '../SearchForm';
import Box from '../ui/Box';
import * as styles from './NavBar.css';
import { useNavbarState } from './useNavbarState';

export default function NavSearch() {
  const search = useNavbarState((s) => s.search);

  return (
    <Box className={styles.search({ active: search })}>
      <Box cx={{ pY: 'md', pX: 'lg' }} colorSet="half" paper>
        <SearchForm />
      </Box>
    </Box>
  );
}
