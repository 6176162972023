import Box, { BoxProps } from '../ui/Box';
import { combineComponentChildren } from '../ui/Polymorphic';
import UnstyledAccordionItem, { UnstyledAccordionItemProps } from './UnstyledAccordion.Item';
import UnstyledAccordionItemBody from './UnstyledAccordion.ItemBody';
import UnstyledAccordionItemCaret from './UnstyledAccordion.ItemCaret';
import UnstyledAccordionItemHandle from './UnstyledAccordion.ItemHandle';
import UnstyledAccordionItemLabel from './UnstyledAccordion.ItemLabel';
import UnstyledAccordionProvider, {
  UnstyledAccordionProviderProps,
} from './UnstyledAccordionProvider';

type UnstyledAccordionProps = UnstyledAccordionProviderProps & {
  items?: UnstyledAccordionItemProps[];
  BoxProps?: BoxProps;
};

const UnstyledAccordionRoot = ({ children, items, BoxProps, ...props }: UnstyledAccordionProps) => {
  return (
    <UnstyledAccordionProvider {...props}>
      <Box {...BoxProps}>
        {items?.map((item, index) => {
          return <UnstyledAccordionItem key={index} {...item} />;
        })}
        {children}
      </Box>
    </UnstyledAccordionProvider>
  );
};

const Accordion = combineComponentChildren(UnstyledAccordionRoot, {
  Item: UnstyledAccordionItem,
  ItemCaret: UnstyledAccordionItemCaret,
  ItemHandle: UnstyledAccordionItemHandle,
  ItemBody: UnstyledAccordionItemBody,
  ItemLabel: UnstyledAccordionItemLabel,
});

export default Accordion;
