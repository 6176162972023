import { DividerStyleProps, useDividerStyle } from './useDividerStyle';

import React from 'react';

export type DividerProps = React.ComponentPropsWithoutRef<'span'> & DividerStyleProps;

const Divider = (props: DividerProps) => {
  const styledProps = useDividerStyle(props);

  return <span {...styledProps} />;
};

export default Divider;
