import { PaletteMode, ThemeOptions } from '@mui/material';
import { rawColorValues } from '@/theme/partials/palette.css';

export const getThemePalette = (mode: PaletteMode): ThemeOptions['palette'] => {
  const isDark = mode === 'dark';

  return {
    mode,
    primary: {
      main: rawColorValues.black,
      contrastText: rawColorValues.white,
    },
    divider: isDark ? rawColorValues.white : rawColorValues.black,
    background: {
      ...(isDark
        ? {
            paper: '#000',
          }
        : {
            paper: '#FFF',
          }),
    },
  };
};
