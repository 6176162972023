import React from 'react';

export default function Logo() {
  return (
    <svg height="48" viewBox="0 0 131 48" fill="none" role="img" focusable="false">
      <title>Melbourne Arts Precinct Transformation logo – home</title>
      <g>
        <path
          d="M0.116211 0.250977H2.73853L7.43686 11.595L12.1352 0.250977H14.7575V14.9581H12.3879V5.62801L8.49535 14.9581H6.37837L2.48586 5.62801V14.9581H0.116211V0.250977Z"
          fill="currentColor"
        />
        <path
          d="M24.1469 8.53016C23.8259 7.30964 22.6855 6.46885 21.3129 6.46885C19.9402 6.46885 18.793 7.30964 18.4789 8.53016H24.1469ZM15.9385 9.7032C15.9385 6.71973 18.3081 4.32617 21.3129 4.32617C24.3176 4.32617 26.6873 6.71973 26.6873 9.7032C26.6873 9.97443 26.6668 10.2524 26.6258 10.5033H18.3969C18.6496 11.8255 19.8583 12.8969 21.3812 12.8969C22.4601 12.8969 23.3274 12.5578 23.7713 11.8866H26.5439C25.8268 13.7987 23.7713 15.0802 21.3812 15.0802C18.315 15.0802 15.9453 12.6867 15.9453 9.7032"
          fill="currentColor"
        />
        <path d="M30.0748 0H27.7461V14.958H30.0748V0Z" fill="currentColor" />
        <path
          d="M39.8059 9.70307C39.8059 7.89264 38.5767 6.55008 36.7397 6.55008C34.9027 6.55008 33.694 7.89264 33.694 9.70307C33.694 11.5135 34.9027 12.8561 36.7397 12.8561C38.5767 12.8561 39.8059 11.5135 39.8059 9.70307ZM33.7349 13.8664V14.958H31.4062V0H33.7349V5.54655C34.3905 4.83458 35.5309 4.32604 36.8421 4.32604C39.9971 4.32604 42.0867 6.42803 42.0867 9.70307C42.0867 12.9781 39.9903 15.0801 36.8421 15.0801C35.4422 15.0801 34.411 14.5105 33.7349 13.8596"
          fill="currentColor"
        />
        <path
          d="M51.6681 9.7032C51.6681 7.87244 50.4389 6.55021 48.6224 6.55021C46.8059 6.55021 45.5767 7.87244 45.5767 9.7032C45.5767 11.534 46.8059 12.8562 48.6224 12.8562C50.4389 12.8562 51.6681 11.534 51.6681 9.7032ZM43.2002 9.7032C43.2002 6.63836 45.4879 4.32617 48.6156 4.32617C51.7432 4.32617 54.0309 6.63836 54.0309 9.7032C54.0309 12.768 51.7432 15.0802 48.6156 15.0802C45.4879 15.0802 43.2002 12.768 43.2002 9.7032Z"
          fill="currentColor"
        />
        <path
          d="M55.2529 10.7543V4.45508H57.5816V10.2525C57.5816 12.1036 58.3669 12.897 59.8898 12.897C61.4126 12.897 62.2185 11.8053 62.2185 10.2525V4.45508H64.5471V14.9583H62.2185V13.7649C61.7336 14.3955 60.7776 15.0871 59.3162 15.0871C56.6938 15.0871 55.2529 13.5547 55.2529 10.7611"
          fill="currentColor"
        />
        <path
          d="M65.9541 4.45506H68.2828V5.84509C68.6447 5.00429 69.4915 4.33301 70.7549 4.33301C71.2602 4.33301 71.5197 4.39403 71.6904 4.46184V6.89608C71.4582 6.79437 71.0963 6.72657 70.7139 6.72657C69.3139 6.72657 68.2759 7.64873 68.2759 9.33032V14.9582H65.9473V4.45506H65.9541Z"
          fill="currentColor"
        />
        <path
          d="M72.8652 4.45506H75.1939V5.65523C75.6788 5.02463 76.6348 4.33301 78.0894 4.33301C80.7117 4.33301 82.1526 5.86543 82.1526 8.65904V14.9582H79.824V9.16081C79.824 7.3097 79.0386 6.51637 77.6045 6.51637C75.9997 6.51637 75.1939 7.60805 75.1939 9.16081V14.9582H72.8652V4.45506Z"
          fill="currentColor"
        />
        <path
          d="M91.5424 8.53016C91.2283 7.30964 90.081 6.46885 88.7084 6.46885C87.3358 6.46885 86.1885 7.30964 85.8744 8.53016H91.5424ZM83.334 9.7032C83.334 6.71973 85.7036 4.32617 88.7084 4.32617C91.7131 4.32617 94.0828 6.71973 94.0828 9.7032C94.0828 9.97443 94.0623 10.2524 94.0213 10.5033H85.7924C86.0451 11.8255 87.2538 12.8969 88.7767 12.8969C89.8556 12.8969 90.7229 12.5578 91.1668 11.8866H93.9394C93.2223 13.7987 91.1668 15.0802 88.7767 15.0802C85.7105 15.0802 83.3408 12.6867 83.3408 9.7032"
          fill="currentColor"
        />
        <path
          d="M9.18496 26.77L6.66507 19.8605L4.14518 26.77H9.17813H9.18496ZM5.47683 16.7075H7.84648L13.3233 31.4147H10.8512L9.96346 29.0008H3.36668L2.47891 31.4147H0L5.47683 16.7075Z"
          fill="currentColor"
        />
        <path
          d="M14.375 20.9116H16.7037V22.3016C17.0656 21.4608 17.9124 20.7896 19.1758 20.7896C19.6811 20.7896 19.9338 20.8506 20.1045 20.9184V23.3526C19.8723 23.2509 19.5104 23.1831 19.128 23.1831C17.7348 23.1831 16.6968 24.1053 16.6968 25.7869V31.4148H14.3682V20.9116H14.375Z"
          fill="currentColor"
        />
        <path
          d="M23.2254 23.0948H21.0879V20.9114H23.2254V16.4565H25.554V20.9114H28.7295V23.0948H25.554V31.4146H23.2254V23.0948Z"
          fill="currentColor"
        />
        <path
          d="M28.6406 27.9499H30.86C30.86 28.8314 31.5566 29.3603 32.2531 29.3603C33.059 29.3603 33.6053 28.8992 33.6053 28.0584C33.6053 26.8785 32.5673 26.7972 31.5293 26.5056C30.4708 26.214 29.3304 25.3936 29.3304 23.6917C29.3304 21.9897 30.6415 20.7896 32.2531 20.7896C33.3526 20.7896 34.1789 21.271 34.773 22.1321L33.3321 23.5425C33.1614 23.1221 32.8268 22.8509 32.4034 22.8509C31.8502 22.8509 31.5566 23.2306 31.5566 23.651C31.5566 24.5731 32.738 24.6003 33.8853 25.0139C34.9642 25.4139 35.9408 26.2344 35.9408 28.0177C35.9408 30.1197 34.3974 31.5504 32.26 31.5504C30.1225 31.5504 28.6406 29.9569 28.6406 27.9567"
          fill="currentColor"
        />
        <path
          d="M46.8945 23.8068C48.3764 23.8068 49.3051 22.8169 49.3051 21.3726C49.3051 19.9283 48.3764 18.9383 46.8945 18.9383H44.4634V23.8136H46.8945V23.8068ZM42.0938 16.7075H47.0243C49.7354 16.7075 51.7226 18.579 51.7226 21.3726C51.7226 24.1662 49.7354 26.0377 47.0243 26.0377H44.4634V31.4147H42.0938V16.7075Z"
          fill="currentColor"
        />
        <path
          d="M52.8154 20.9116H55.1441V22.3016C55.506 21.4608 56.3528 20.7896 57.6162 20.7896C58.1215 20.7896 58.3742 20.8506 58.5449 20.9184V23.3526C58.3127 23.2509 57.9508 23.1831 57.5684 23.1831C56.1753 23.1831 55.1373 24.1053 55.1373 25.7869V31.4148H52.8086V20.9116H52.8154Z"
          fill="currentColor"
        />
        <path
          d="M67.1762 24.9867C66.8552 23.7662 65.7148 22.9254 64.3422 22.9254C62.9695 22.9254 61.8223 23.7662 61.5081 24.9867H67.1762ZM58.9678 26.1597C58.9678 23.1763 61.3374 20.7827 64.3422 20.7827C67.3469 20.7827 69.7166 23.1763 69.7166 26.1597C69.7166 26.431 69.6961 26.709 69.6551 26.9599H61.4262C61.6789 28.2821 62.8876 29.3534 64.4105 29.3534C65.4894 29.3534 66.3567 29.0144 66.8006 28.3431H69.5732C68.8561 30.2552 66.8006 31.5368 64.4105 31.5368C61.3443 31.5368 58.9746 29.1432 58.9746 26.1597"
          fill="currentColor"
        />
        <path
          d="M70.6387 26.1597C70.6387 23.0949 72.9469 20.7827 76.1155 20.7827C78.3554 20.7827 80.049 21.9151 80.8958 23.7662L78.8471 24.8172C78.3827 23.5967 77.3447 22.9661 76.0745 22.9661C74.299 22.9661 73.0083 24.268 73.0083 26.1597C73.0083 28.0515 74.299 29.3534 76.0745 29.3534C77.3447 29.3534 78.3827 28.7228 78.8471 27.5023L80.8958 28.5533C80.049 30.4044 78.3554 31.5368 76.1155 31.5368C72.94 31.5368 70.6387 29.2246 70.6387 26.1597Z"
          fill="currentColor"
        />
        <path
          d="M81.9683 20.9115H84.297V31.4147H81.9683V20.9115ZM81.5654 18.1586C81.5654 17.2771 82.262 16.5854 83.1293 16.5854C83.9965 16.5854 84.6931 17.2771 84.6931 18.1586C84.6931 19.04 83.9965 19.7317 83.1293 19.7317C82.262 19.7317 81.5654 19.04 81.5654 18.1586Z"
          fill="currentColor"
        />
        <path
          d="M85.6279 20.9116H87.9566V22.1118C88.4415 21.4812 89.3975 20.7896 90.8521 20.7896C93.4744 20.7896 94.9153 22.322 94.9153 25.1156V31.4148H92.5866V25.6174C92.5866 23.7662 91.8013 22.9729 90.3672 22.9729C88.7624 22.9729 87.9566 24.0646 87.9566 25.6174V31.4148H85.6279V20.9116Z"
          fill="currentColor"
        />
        <path
          d="M95.8994 26.1597C95.8994 23.0949 98.2076 20.7827 101.376 20.7827C103.616 20.7827 105.31 21.9151 106.157 23.7662L104.101 24.8172C103.637 23.5967 102.599 22.9661 101.328 22.9661C99.5529 22.9661 98.2622 24.268 98.2622 26.1597C98.2622 28.0515 99.5529 29.3534 101.328 29.3534C102.599 29.3534 103.637 28.7228 104.101 27.5023L106.157 28.5533C105.31 30.4044 103.616 31.5368 101.376 31.5368C98.2008 31.5368 95.8994 29.2246 95.8994 26.1597Z"
          fill="currentColor"
        />
        <path
          d="M108.744 23.0948H106.606V20.9114H108.744V16.4565H111.073V20.9114H114.241V23.0948H111.073V31.4146H108.744V23.0948Z"
          fill="currentColor"
        />
        <path
          d="M3.36668 35.3949H0V33.1641H9.09618V35.3949H5.73633V47.8712H3.36668V35.3949Z"
          fill="#FFA400"
        />
        <path
          d="M9.00097 37.3681H11.3296V38.7582C11.6916 37.9174 12.5384 37.2461 13.8017 37.2461C14.3071 37.2461 14.5597 37.3071 14.7305 37.3749V39.8092C14.4983 39.7075 14.1363 39.6397 13.7539 39.6397C12.3608 39.6397 11.3228 40.5618 11.3228 42.2434V47.8713H8.99414V37.3681H9.00097Z"
          fill="#FFA400"
        />
        <path
          d="M23.8947 42.6231C23.8947 40.6906 22.5836 39.4701 20.8695 39.4701C19.1554 39.4701 17.7623 40.6906 17.7623 42.6231C17.7623 44.5556 19.1144 45.7761 20.8695 45.7761C22.6245 45.7761 23.8947 44.5556 23.8947 42.6231ZM15.4746 42.6231C15.4746 39.4701 17.7623 37.2461 20.7875 37.2461C22.1875 37.2461 23.3689 37.897 23.8128 38.4666V37.3749H26.1824V47.8781H23.8128V46.7864C23.3689 47.356 22.208 48.0069 20.7875 48.0069C17.7623 48.0069 15.4746 45.7761 15.4746 42.6299"
          fill="#FFA400"
        />
        <path
          d="M27.4521 37.3681H29.7808V38.5683C30.2657 37.9377 31.2217 37.2461 32.6763 37.2461C35.2986 37.2461 36.7395 38.7785 36.7395 41.5721V47.8713H34.4109V42.0739C34.4109 40.2228 33.6255 39.4295 32.1914 39.4295C30.5866 39.4295 29.7808 40.5211 29.7808 42.0739V47.8713H27.4521V37.3681Z"
          fill="#FFA400"
        />
        <path
          d="M37.5869 44.4064H39.8063C39.8063 45.2879 40.5029 45.8168 41.1994 45.8168C42.0053 45.8168 42.5516 45.3557 42.5516 44.5149C42.5516 43.3351 41.5136 43.2537 40.4756 42.9622C39.4171 42.6706 38.2766 41.8501 38.2766 40.1482C38.2766 38.4463 39.5878 37.2461 41.1994 37.2461C42.2989 37.2461 43.1252 37.7275 43.7193 38.5887L42.2852 39.999C42.1145 39.5786 41.7799 39.3074 41.3565 39.3074C40.8034 39.3074 40.5097 39.6871 40.5097 40.1075C40.5097 41.0297 41.698 41.0568 42.8384 41.4704C43.9174 41.8705 44.8939 42.6909 44.8939 44.4742C44.8939 46.5762 43.3506 48.0069 41.2131 48.0069C39.0756 48.0069 37.5937 46.4067 37.5937 44.4132"
          fill="#FFA400"
        />
        <path
          d="M46.6143 39.5513H44.75V37.368H46.6143V35.9779C46.6143 34.0455 48.0757 33.0352 49.6805 33.0352C50.9302 33.0352 51.736 33.3742 52.3028 34.1472L50.7595 35.6999C50.6093 35.4897 50.3976 35.2185 49.8512 35.2185C49.1957 35.2185 48.943 35.6999 48.943 36.1203V37.3612H51.8658V39.5445H48.943V47.8644H46.6143V39.5445V39.5513Z"
          fill="#FFA400"
        />
        <path
          d="M60.5665 42.6231C60.5665 40.7924 59.3373 39.4701 57.5208 39.4701C55.7043 39.4701 54.4751 40.7924 54.4751 42.6231C54.4751 44.4539 55.7043 45.7761 57.5208 45.7761C59.3373 45.7761 60.5665 44.4539 60.5665 42.6231ZM52.0986 42.6231C52.0986 39.5583 54.3863 37.2461 57.514 37.2461C60.6417 37.2461 62.9294 39.5583 62.9294 42.6231C62.9294 45.688 60.6417 48.0002 57.514 48.0002C54.3863 48.0002 52.0986 45.688 52.0986 42.6231Z"
          fill="#FFA400"
        />
        <path
          d="M63.9053 37.3681H66.2339V38.7582C66.5959 37.9174 67.4427 37.2461 68.706 37.2461C69.2114 37.2461 69.464 37.3071 69.6348 37.3749V39.8092C69.4026 39.7075 69.0406 39.6397 68.6582 39.6397C67.2651 39.6397 66.2271 40.5618 66.2271 42.2434V47.8713H63.8984V37.3681H63.9053Z"
          fill="#FFA400"
        />
        <path
          d="M70.6797 37.3681H73.0084V38.5683C73.5752 37.7885 74.5927 37.2461 75.7604 37.2461C77.0511 37.2461 77.9594 37.775 78.533 38.6971C79.3388 37.8563 80.2061 37.2461 81.7289 37.2461C83.8869 37.2461 85.1981 38.3988 85.1981 40.9822V47.8713H82.8694V41.5721C82.8694 40.2092 82.2343 39.4295 80.9846 39.4295C79.9056 39.4295 79.0998 40.2092 79.0998 41.5721V47.8713H76.7711V41.5721C76.7711 40.2092 76.1155 39.4295 74.8863 39.4295C73.8073 39.4295 73.0015 40.2092 73.0015 41.5721V47.8713H70.6729V37.3681H70.6797Z"
          fill="#FFA400"
        />
        <path
          d="M94.6291 42.6231C94.6291 40.6906 93.3179 39.4701 91.6039 39.4701C89.8898 39.4701 88.4967 40.6906 88.4967 42.6231C88.4967 44.5556 89.8488 45.7761 91.6039 45.7761C93.3589 45.7761 94.6291 44.5556 94.6291 42.6231ZM86.209 42.6231C86.209 39.4701 88.4967 37.2461 91.5151 37.2461C92.915 37.2461 94.0964 37.897 94.5403 38.4666V37.3749H96.91V47.8781H94.5403V46.7864C94.0964 47.356 92.9355 48.0069 91.5151 48.0069C88.4899 48.0069 86.209 45.7761 86.209 42.6299"
          fill="#FFA400"
        />
        <path
          d="M106.607 37.368H108.936V47.8712H106.607V37.368ZM106.204 34.6151C106.204 33.7336 106.901 33.042 107.768 33.042C108.635 33.042 109.332 33.7336 109.332 34.6151C109.332 35.4966 108.635 36.1882 107.768 36.1882C106.901 36.1882 106.204 35.4966 106.204 34.6151Z"
          fill="#FFA400"
        />
        <path
          d="M118.435 42.6231C118.435 40.7924 117.206 39.4701 115.389 39.4701C113.573 39.4701 112.343 40.7924 112.343 42.6231C112.343 44.4539 113.573 45.7761 115.389 45.7761C117.206 45.7761 118.435 44.4539 118.435 42.6231ZM109.974 42.6231C109.974 39.5583 112.261 37.2461 115.389 37.2461C118.517 37.2461 120.804 39.5583 120.804 42.6231C120.804 45.688 118.517 48.0002 115.389 48.0002C112.261 48.0002 109.974 45.688 109.974 42.6231Z"
          fill="#FFA400"
        />
        <path
          d="M121.713 37.3681H124.042V38.5683C124.526 37.9377 125.482 37.2461 126.944 37.2461C129.566 37.2461 131.007 38.7785 131.007 41.5721V47.8713H128.678V42.0739C128.678 40.2228 127.893 39.4295 126.459 39.4295C124.854 39.4295 124.048 40.5211 124.048 42.0739V47.8713H121.72V37.3681H121.713Z"
          fill="#FFA400"
        />
        <path
          d="M100.093 39.5513H97.9551V37.368H100.093V32.9131H102.421V37.368H105.59V39.5513H102.421V47.8711H100.093V39.5513Z"
          fill="#FFA400"
        />
      </g>
    </svg>
  );
}
