'use client';

import { useTranslations } from 'next-intl';
import Card from '../Card';
import Divider from '../ui/Divider';
import { EntryCardProps } from '../Blocks/BlockComponents/EntryCardBlock/useEntryCard';

export type JobPositionCardProps = EntryCardProps<'jobPosition'>;

export const JobPositionCard = ({ item, ...props }: JobPositionCardProps) => {
  const { title, pageTitle, summary: description, linkField } = item;
  const t = useTranslations('common');

  return (
    <Card
      fullHeight
      elevation={false}
      {...props}
      item={{
        href: linkField?.href,
        title: pageTitle ?? title,
        description,
        link: linkField,
      }}>
      <Divider direction="horizontal" size="md" color="accent" />
      <Card.Body cx={{ mT: 'md', mB: 'sm', display: 'flex', rowGap: 'xs' }}>
        <Card.Title variant="h5" medium />
        <Card.Description />
      </Card.Body>
      <Card.Foot>
        <Card.Cta as="div" endIcon="arrowOutward">
          {t('applyNow')}
        </Card.Cta>
      </Card.Foot>
    </Card>
  );
};
