'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import React, { useState } from 'react';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import * as styles from './NavBar.css';
import { useNavbarState } from './useNavbarState';

export default function NavItem(props: NavMenuItem) {
  const { title, subItems, href, id } = props;
  const setSearch = useNavbarState((s) => s.setSearch);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(null);
    setSearch(false);
  };

  const showSubItems = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    if (!!anchorEl) {
      setAnchorEl(null);
      setSearch(false);
    } else {
      setAnchorEl(e.currentTarget);
      setSearch(false);
    }
  };

  const SubItemParent = () => {
    if (!props.subItems?.length) return null;
    return (
      <Btn variant="nav" onClick={handleToggle} endIcon="chevronDown" {...{ id }}>
        {title}
      </Btn>
    );
  };

  const Item = () => {
    if (props.subItems?.length) return null;
    return (
      <Btn
        variant="nav"
        onClick={() => setSearch(false)}
        endIcon={!!props.subItems?.length ? 'chevronDown' : undefined}
        {...{ href, id }}>
        {title}
      </Btn>
    );
  };

  const SubItem = ({ id, href, title }: NavMenuItem) => {
    if (!href) return null;
    return (
      <Btn variant="nav" key={id} onClick={handleClick} {...{ href, id }}>
        {title}
      </Btn>
    );
  };

  return (
    props && (
      <Box as="li" className={styles.navItem}>
        <Item />
        <SubItemParent />
        {!!subItems?.length && open && (
          <Box
            id={id + '-subItem'}
            tabIndex={-1}
            className={styles.menuWrapper}
            onMouseEnter={showSubItems}
            onMouseLeave={handleClick}>
            <Flex className={styles.menu}>
              {subItems?.map((subItem) => (
                <SubItem key={subItem.id} {...subItem} />
              ))}
            </Flex>
          </Box>
        )}
      </Box>
    )
  );
}
