'use client';

import { Entry } from '@/lib/parsers/entries';
import { useView } from '@/lib/store/hooks';
import { fmtCategoryNames } from '@/lib/utils/format';
import { PageHeaderProps } from '.';
import Btn, { BtnProps } from '../ui/Btn';
import Txt from '../ui/Txt';

type PageCategoryProps = BtnProps & Partial<PageHeaderProps>;

export default function PageCategory({ children, ...props }: PageCategoryProps) {
  const page = useView() as Entry<'article', 'default'>;
  const articleCategory = fmtCategoryNames(page?.contentTypeCategorySingle);

  return (
    <Btn variant="filled" color="accent" size="tiny" tabIndex={-1} {...props}>
      <Txt as="span" allCaps>
        {children || articleCategory}
      </Txt>
    </Btn>
  );
}
