'use client';

import Shim from '../Shim';
import * as styles from './NavBar.css';
import { useNavbarState } from './useNavbarState';

export default function NavSearchShim() {
  const search = useNavbarState((s) => s.search);
  const setSearch = useNavbarState((s) => s.setSearch);

  return (
    <Shim className={styles.searchShim({ active: search })} onClick={() => setSearch(false)} />
  );
}
