import React from 'react';
import { View } from '../getters/getEntryByUri';
import { appContext } from './context';
import { ColorTheme } from '@/theme/partials/palette.css';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { filterBlocks, parseSanitisedBlocks, SanitisedBlock } from '../parsers/blocks';

export const useView = (): View | null => {
  const { view } = React.useContext(appContext) ?? {};
  return view || null;
};

export const useColorTheme = (): ColorTheme | undefined => {
  const { colorTheme } = React.useContext(appContext) ?? {};
  return colorTheme || undefined;
};

export const useViewBlocks = () => {
  const { view } = React.useContext(appContext) ?? {};

  const blocks = maybeGet(view, 'blocks') ?? [];

  const parsedBlocks = parseSanitisedBlocks(blocks as SanitisedBlock[]);

  return filterBlocks(parsedBlocks);
};
