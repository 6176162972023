'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import { useBreakpointMin } from '@/theme';
import NavDrawer from '../NavDrawer';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import * as styles from './NavBar.css';
import NavItem from './NavItem';
import { useNavbarState } from './useNavbarState';
import Box from '../ui/Box';

export type NavMenu = FlexProps<
  'ul',
  {
    items?: NavMenuItem[];
  }
>;

export default function NavMenu({ items, ...props }: NavMenu) {
  const navBreakpoint = useBreakpointMin('nav');
  const search = useNavbarState((s) => s.search);
  const setSearch = useNavbarState((s) => s.setSearch);

  return (
    <Flex
      as="ul"
      direction="row"
      alignItems="center"
      columnGap="gutter"
      className={styles.navMenu}
      {...props}>
      {navBreakpoint &&
        items?.map((item) => {
          return <NavItem key={item.id} {...item} />;
        })}
      <Box as="li">
        <Btn
          variant="icon"
          aria-label="search"
          size="large"
          aria-expanded={search}
          startIcon={search ? 'close' : 'search'}
          disablePadding
          onClick={() => setSearch(!search)}
        />
      </Box>
      {!navBreakpoint && <NavDrawer items={items} />}
    </Flex>
  );
}
