'use client';

import Box, { BoxProps } from '@/components/ui/Box';
import { useColorTheme } from '@/lib/store/hooks';
import * as styles from './ThemeLayout.css';

export type AppThemeProps = BoxProps;

const ThemeLayout = ({ children, colorTheme, ...props }: AppThemeProps) => {
  const theme = useColorTheme();

  return (
    <Box colorTheme={colorTheme ?? theme} colorSet="full" paper {...props} className={styles.root}>
      {children}
    </Box>
  );
};

export default ThemeLayout;
