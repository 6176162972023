'use client';

import { layoutMainId } from '@/layouts/Layout';
import { useState } from 'react';
import NextAnchor from '../ui/NextAnchor';
import * as styles from './NavBar.css';
import { useNavbarState } from './useNavbarState';

export default function NavSkip() {
  const setSearch = useNavbarState((s) => s.setSearch);
  const [focused, setFocused] = useState<boolean>(false);
  const onFocus = () => setFocused(true);

  const handleClick = () => {
    setSearch(false);
    setFocused(false);
  };

  return (
    <NextAnchor
      href={'#' + layoutMainId}
      aria-label="Skip to content button"
      className={styles.skip({ focused })}
      onFocus={onFocus}
      onBlur={handleClick}
      onClick={handleClick}>
      Skip to
      <br /> Content
    </NextAnchor>
  );
}
