'use client';

import React from 'react';
import { useNavbarState } from '../NavBar/useNavbarState';
import Shim, { ShimProps } from '../Shim';

export default function NavShim(props: ShimProps) {
  const { height, fallbackHeight } = useNavbarState();

  return <Shim style={{ minHeight: height ?? fallbackHeight }} {...props} />;
}
