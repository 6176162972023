import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '../ui/Box';
import * as style from './Card.css';
import { CardCta } from './Card.Cta';
import { useCard } from './useCard';

export type CardFootProps = BoxProps<'div'>;

export const CardFoot = ({ children, ...props }: CardFootProps) => {
  const { customText } = useCard();

  return (
    (customText || children) && (
      <Box {...props} {...mergePropsClassName(props, style.foot)}>
        {children || <CardCta />}
      </Box>
    )
  );
};
