'use client';

import { NavMenuItem } from '@/lib/parsers/navigation';
import { rawColorValues } from '@/theme/partials/palette.css';
import { Drawer } from '@mui/material';
import { useState } from 'react';
import UnstyledAccordion from '../UnstyledAccordion';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Flex, { FlexProps } from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './NavDrawer.css';

export type NavDrawer = FlexProps<
  'ul',
  {
    items?: NavMenuItem[];
  }
>;

export default function NavDrawer({ items, ...props }: NavDrawer) {
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const ParentItem = (item: NavMenuItem) => {
    return (
      <Btn
        variant="text"
        {...(!!item.subItems?.length ? { role: undefined } : { href: item.href, role: undefined })}>
        <Txt as="span" variant="h5">
          {item.title}
        </Txt>
      </Btn>
    );
  };

  const SubItem = (item: NavMenuItem) => {
    return (
      <Btn key={item.id} variant="text" {...{ href: item.href }}>
        <Txt as="span" variant="body">
          {item.title}
        </Txt>
      </Btn>
    );
  };

  return (
    <>
      <Box as="li">
        <Btn
          aria-label="Open menu button"
          aria-expanded={open}
          variant="icon"
          size="large"
          startIcon="menu"
          disablePadding
          onClick={toggleDrawer(true)}
        />
      </Box>
      <Drawer tabIndex={-1} anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Flex colorTheme="dark" colorSet="full" paper as="ul" className={styles.inner} {...props}>
          <Flex cx={{ width: 'full', pX: 'sm', pY: 'lg' }} alignItems="end">
            <Btn
              aria-label="Close menu button"
              variant="icon"
              disablePadding
              startIcon="close"
              onClick={toggleDrawer(false)}
              cx={{ fontSize: 'h4' }}
            />
          </Flex>
          <UnstyledAccordion>
            {items?.map((item, index) => {
              return (
                <UnstyledAccordion.Item
                  key={item.id}
                  index={index}
                  isAccordionItem={!!item.subItems?.length}
                  style={{ borderColor: rawColorValues.black25 }}
                  CaretProps={{
                    icon: 'chevronDown',
                    openIcon: 'chevronUp',
                    cx: { fontSize: 'h4' },
                  }}
                  HandleProps={{ cx: { pX: 'sm' }, role: undefined }}
                  LabelProps={{
                    children: <ParentItem {...item} />,
                  }}
                  BodyProps={{ index, cx: { pL: 'sm' } }}>
                  {item.subItems?.map((subItem) => {
                    return <SubItem key={subItem.id} {...subItem} />;
                  })}
                </UnstyledAccordion.Item>
              );
            })}
          </UnstyledAccordion>
        </Flex>
      </Drawer>
    </>
  );
}
