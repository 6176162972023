'use client';

import classNames from 'classnames';
import React from 'react';
import Box, { BoxProps } from '../ui/Box';
import UnstyledAccordionItemBody, {
  UnstyledAccordionItemBodyProps,
} from './UnstyledAccordion.ItemBody';
import UnstyledAccordionItemCaret, {
  UnstyledAccordionItemCaretProps,
} from './UnstyledAccordion.ItemCaret';
import UnstyledAccordionItemHandle, {
  UnstyledAccordionItemHandleProps,
} from './UnstyledAccordion.ItemHandle';
import UnstyledAccordionItemLabel, {
  UnstyledAccordionItemLabelProps,
} from './UnstyledAccordion.ItemLabel';
import * as styles from './UnstyledAccordion.css';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';

export type UnstyledAccordionItemProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    HandleProps?: UnstyledAccordionItemHandleProps;
    LabelProps?: UnstyledAccordionItemLabelProps;
    CaretProps?: UnstyledAccordionItemCaretProps;
    BodyProps?: UnstyledAccordionItemBodyProps;
    index: number;
    isAccordionItem?: boolean;
  }
>;

const UnstyledAccordionItem = ({
  index,
  isAccordionItem = true,
  HandleProps,
  LabelProps,
  CaretProps,
  BodyProps,
  children,
  className,
  ...props
}: UnstyledAccordionItemProps) => {
  const { isActive } = useUnstyledAccordionItem(index);

  return (
    <Box
      className={classNames([
        styles.item({ itemFirst: index === 0, itemOpen: isActive }),
        className,
      ])}
      {...props}>
      <UnstyledAccordionItemHandle index={index} {...HandleProps}>
        <UnstyledAccordionItemLabel {...LabelProps} />
        <UnstyledAccordionItemCaret
          isActive={isActive}
          isAccordionItem={isAccordionItem}
          {...CaretProps}
        />
      </UnstyledAccordionItemHandle>
      {isAccordionItem && (
        <UnstyledAccordionItemBody index={index} {...BodyProps}>
          {children}
        </UnstyledAccordionItemBody>
      )}
    </Box>
  );
};

export default UnstyledAccordionItem;
