'use client';

import useScrollPosition from '@/lib/utils/useScrollPosition';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useEffect, useRef, useState } from 'react';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';
import Box from '../ui/Box';
import Container from '../ui/Container';
import * as styles from './NavBar.css';
import NavSearch from './NavSearch';
import { useNavbarState } from './useNavbarState';

type InnerProps = React.PropsWithChildren;

type Size = {
  height?: number;
};

export default function NavInner({ children }: InnerProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { setHeight, fallbackHeight } = useNavbarState();
  const [{ height }, setSize] = useState<Size>({
    height: undefined,
  });

  const pos = useScrollPosition();
  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    ref,
    onResize,
  });

  useEffect(() => {
    setHeight(height);
  }, [setHeight, height]);

  return (
    <Box
      ref={ref}
      className={styles.inner}
      style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}>
      <Container
        maxWidth="none"
        colorTheme="dark"
        colorSet="full"
        paper
        className={styles.navbar({ withViewColorTheme: pos > 0 })}>
        {children}
      </Container>
      <NavSearch />
    </Box>
  );
}
