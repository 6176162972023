'use client';

import { createContext } from 'react';
import { View } from '../getters/getEntryByUri';
import { ColorTheme } from '@/theme/partials/palette.css';

export type AppContext = {
  view?: View | null;
  colorTheme?: ColorTheme;
};

const initialState: AppContext = { colorTheme: 'default' };

export const appContext = createContext<AppContext>(initialState);

export const { Provider: AppContextProvider } = appContext;

export default AppContextProvider;
