import { unpackProps } from '@liquorice/allsorts-craftcms-nextjs';
import Container, { ContainerProps } from '../ui/Container';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import { GridColSpanVariants } from '../ui/Grid/Grid.css';

export type SimpleGridProps = PropsOverride<
  GridProps<'div'>,
  {
    ItemProps?: GridColProps<'div'>;
    children: JSX.Element[];
    container?: boolean;
    ContainerProps?: ContainerProps;
  } & GridColSpanVariants
>;

const SimpleGrid = ({
  container,
  ContainerProps,
  ItemProps,
  children,
  ...props
}: SimpleGridProps) => {
  const { rest, unpacked: gridItemProps } = unpackProps(props, [
    'span',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
  ]);

  const gridColProps: Omit<GridColProps<'div'>, 'ref'> = { ...gridItemProps, ...ItemProps };

  const output = (
    <Grid
      rowGutter
      cx={{
        // rowGap: 'cardRow',
        alignItems: 'stretch',
      }}
      {...rest}>
      {children.map((v, i) => (
        <Grid.Col key={i} {...gridColProps}>
          {v}
        </Grid.Col>
      ))}
    </Grid>
  );

  return container ? <Container {...ContainerProps}>{output}</Container> : output;
};

export default SimpleGrid;
