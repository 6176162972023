import { Collapse } from '@mui/material';
import Box, { BoxProps } from '../ui/Box';
import { useUnstyledAccordionItem } from './useUnstyledAccordion';
import * as styles from './UnstyledAccordion.css';

export type UnstyledAccordionItemBodyProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  { index: number }
>;

const UnstyledAccordionItemBody = ({
  children,
  index,
  ...props
}: UnstyledAccordionItemBodyProps) => {
  const { isActive } = useUnstyledAccordionItem(index);

  return (
    <Box {...props}>
      <Collapse
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
        onExited={() => !isActive}
        onEntering={() => isActive}
        onEntered={() => isActive}
        className={styles.panel}
        in={isActive}
        aria-expanded={isActive ? 'true' : 'false'}>
        <Box className={styles.panelInner}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default UnstyledAccordionItemBody;
